import React, { useCallback, useRef, useEffect } from 'react';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.jpg';
import { Helmet } from 'react-helmet';

import { FaChevronDown } from 'react-icons/fa';

import {
  Container,
  Content,
  Background,
  AnimatedDiv,
  CaseCard,
  CaseCardsContainer,
  CaseContainer,
  Manifesto,
  ContainerWithBackground,
  Title,
} from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import TextPopper from '../../components/TextPopper';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import Vimeo from '../../components/Vimeo';

import { Pattern } from '../../styles/ThemeComponents';
import VideoBackground from '../../components/VideoBackground';
import SliderContainer from '../../components/Slider';
import Scroller from '../../components/Scroller';
import CasesSlider from '../CasesSlider';

const video = '/assets/caseSuergs2019.mp4';
const videoThumb = '/assets/caseSuergs2019-thumb.jpg';

interface CasesData {
  email: string;
  password: string;
}

const Cases: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = useCallback(
    async (data: CasesData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Preencha um email válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const { email, password } = data;

        history.push('/dashboard');
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login',
        });
      }
    },
    [Cases, addToast, history],
  );

  return (
    <>
      <Helmet>
        <title>Home - Inventa Evento</title>
        <link rel="canonical" href="http://inventaevento.com.br" />
      </Helmet>
      <VideoBackground
        path={video}
        thumb={videoThumb}
        background="rgb(65, 203, 192)"
      >
        <Content>
          <Title> Suergs Fórum</Title>
          <p>
            {' '}
            <TextPopper
              messages={[
                '20 horas de palestras',
                '700 Participantes',
                '+ de 20 Palestrantes Nacionais e Internacionais',
                '13 expositores',
              ]}
              time={3000}
            />
          </p>
        </Content>

        <Scroller type="main" targetID="fotos" />
      </VideoBackground>

      <Container id="fotos">
        <div className="p50">
          <div className="size400">
            <label>17/05/2019</label>
            <Title>Saúde, diversidade e futuro</Title>

            <p>
              Entre os dias 15 e 17 de maio, a Unimed Federação/RS realizou dois
              de seus principais eventos: o Simpósio da Unimeds do Estado do Rio
              Grande do Sul (Suergs 2019) e o Fórum Instituto Unimed/RS - com o
              tema "saúde, diversidade e futuro".
              <br />
              <br />
              Os encontros tiveram lotação e aconteceram no Theatro São Pedro,
              em Porto Alegre. O Suergs é um evento de cunho técnico, voltado a
              dirigentes e lideranças do Sistema Unimed-RS.
              <br />
              <br />
              O Fórum Instituto Unimed/RS, evento gratuito e aberto à
              comunidade, chegou à sua 10ª edição.
              <br />
              <br />
              Fotos créditos: ©Mathias Cramer/temporealfoto.
            </p>
          </div>
        </div>
        <div className="p50 secondary">
          <SliderContainer list={[
  <div>
  <img src="/assets/suergs/4.jpg" alt="Suergs Fórum 2019" />
</div>,
<div>
  <img src="/assets/suergs/1.jpg" alt="Suergs Fórum 2019" />
</div>,
<div>
  <img src="/assets/suergs/2.jpg" alt="Suergs Fórum 2019" />
</div>,
<div>
  <img src="/assets/suergs/7.jpg" alt="Suergs Fórum 2019" />
</div>,
<div>
  <img src="/assets/suergs/5.jpg" alt="Suergs Fórum 2019" />
</div>,
<div>
  <img src="/assets/suergs/6.jpg" alt="Suergs Fórum 2019" />
</div>

          ]}>
          
          </SliderContainer>
        </div>

        <Scroller targetID="video" />
      </Container>
      <ContainerWithBackground>
        <div
          style={{
            width: '100%',
            padding: '40px 20px',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      </ContainerWithBackground>
      <Container id="video">
        <div className="p50 secondary">
          <div className="size400">
            <label>17/05/2019</label>
            <h2>Um Evento, um Legado.</h2>

            <p>
              Nossa equipe preparou um super vídeo para comemorar mais este
              evento de sucesso em parceria com a Unimed. O Suergs foi um evento
              técnico de alto impacto com mais de 700 participantes, 20 horas de
              palestras e uma equipe empenhada em fazer tudo acontecer.
              <br />
              <br />
              <label>Confira você mesmo!</label>
            </p>
          </div>
        </div>
        <div className="p50">
          <div className="videoContainer">
            <Vimeo id="427126612" />
          </div>

          <Scroller targetID="cases" />
        </div>
      </Container>

      <ContainerWithBackground>
        <div
          style={{
            width: '100%',
            padding: '40px 20px',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      </ContainerWithBackground>
      <div id="cases2"/>
      <CasesSlider/>
    </>
  );
};
export default Cases;
