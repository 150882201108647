import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
// import background from '../../assets/background.jpg';
import background from '../../assets/background.jpg';
import { ThemeDefinitions } from '../../styles/ThemeComponents';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

export const Wrapper = styled.div`
  @font-face {
    font-family: ClanOT2;
    src: url('/fonts/clanot-news.otf') format('opentype');
  }

  @font-face {
    font-family: ClanOT2;
    font-weight: bold;
    src: url('/fonts/clanot-bold.otf') format('opentype');
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong {
    font-family: 'ClanOT2', Arial, Helvetica, sans-serif;
    font-weight: bold;
  }

  span,
  p,
  label,
  div,
  aside,
  header,
  footer,
  span {
    font-family: 'ClanOT2', Arial, Helvetica, sans-serif;
  }
`;

export const Container = styled.div`
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #222;
  color: #fff;
  justify-content: space-between;
  position: relative;
  padding-top: 150px;

  @media (max-width: 750px) {
    flex-direction: column;
    padding: 20px 0px;
    padding-bottom: 70px;
  }

  label {
    display: inline-block;
    background: rgb(88, 41, 93);
    padding: 10px;
    color: #fff;
    margin-bottom: 10px;
  }

  label.inverted {
    background: rgb(65, 193, 202);
    color: rgb(88, 41, 93);
  }

  > div.secondary {
    background: rgb(65, 193, 202);

    > div {
      width: 100%;
    }
  }

  > div.p50 {
    flex-direction: column;
    display: flex;
    width: 50%;
    min-height: 50vh;
    padding: 20px;
    justify-content: center;
    align-items: center;

    @media (max-width: 750px) {
      width: 100%;
      margin: 20px 0px;
    }

    h2 {
      font-size: 60px;
      margin-bottom: 20px;
      font-weight: bold;
      text-align: left;

      @media (max-width: 750px) {
        font-size: 48px;
      }
    }

    p {
      font-size: 18px;
      line-height: 1.4;

      text-align: justify;

      @media (max-width: 750px) {
        font-size: 16px;
      }
    }
  }

  div.size400 {
    max-width: 500px;
  }

  img {
    width: 100%;
  }
`;

export const AnimatedDiv = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${ThemeDefinitions.bodyBackgroundWithOpacity};
  padding: 40px 0px;
  animation: ${fadeFromLeft} 1s;

  form {
    margin: 30px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4edef;
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4edef')};
      }
    }
  }

  > a {
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none;

    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#ff9000')};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  z-index: 1;

  p {
    font-size: 42px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 750px) {
      font-size: 24px;
    }
  }

  img {
    width: 200px;
    max-width: 300px;
    margin: 10px;
  }

  @media (max-width: 750px) {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: url(${background}) no-repeat top left;
  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: 0;
`;

interface ICard {
  background: string;
}

export const CaseCard = styled.div<ICard>`
  background: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  transition: 0.4s all;
  position: relative;
  border-radius: 15px;

  > div {
    padding: 10px 20px;
  }

  header {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    width: 100%;
    background: rgba(88, 41, 93, 0.9);
    color: #fff;
    font-size: 18px;
    font-weight: bold;

    p {
      color: rgb(65, 193, 202);
    }
  }

  &:hover {
    transform: translateY(10px);
    box-shadow: 10px 15px 15px #333;
  }
`;

export const CaseContainer = styled(Container)`
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  cursor: pointer;
  overflow-x: hidden;
  width: 100%;
  text-align: center;

  .slick-next {
    z-index: 200;
    right: 70px;
  }

  .slick-prev {
    z-index: 200;
    left: 70px;
  }

  .slick-next:before,
  .slick-prev:before {
    font-size: 42px;
  }

  @media (max-width: 750px) {
    min-height: 90vh;
  }
`;

export const Title = styled.h2`
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 2px #333;

  @media (max-width: 750px) {
    font-size: 42px;
  }
`;

interface IBackground {
  background: string;
}

export const Photo = styled.div<IBackground>`
width : 100%;
height: 50vh;

background : url(${props => props.background}) no-repeat center;
background-size: cover;
`;

export const CaseCardsContainer = styled.aside`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  > div {
    width: 300px;
    height: 400px;
    margin: 15px;
    @media (max-width: 750px) {
      width: 300px;
      height: 400px;
      margin: 15px;
    }
  }
`;
