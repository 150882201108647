import React, { Children } from 'react';

import { Container } from './styles';

interface IImageBackgroundProps {
  path?: string;
position ?: string;
  background?: string;
}

const ImageBackground: React.FC<IImageBackgroundProps> = ({
  children,
  path = '',
  position = 'center',

  background = '',
}) => {
  return <Container position={position} background={background}>{children}</Container>;
};

export default ImageBackground;
