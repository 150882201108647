import styled from 'styled-components';
import background from '../../../assets/background.jpg';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  padding-top: 0px;
`;

export const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: url(${background}) no-repeat top left;
  background-size: cover;
  width: 100%;
  height: 100vh;
  z-index: -1;
`;

export const Footer = styled.div`
  width: 100%;
  min-height: 300px;
  padding: 20px;
  background: rgba(65, 203, 192, 0.95);
  color: #333;
  display: flex;

  @media (max-width: 750px) {
    flex-direction: column;
    padding-bottom: 100px;
  }

  > div {
    flex-direction: column;
    display: flex;
    width: calc(33% - 10px);
    height: 100%;
    padding: 20px;
    align-items: center;
    height: auto;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    font-weight: bold;

    @media (max-width: 750px) {
      width: 100%;
      justify-content: center;
      text-align: center;
    }

    img {
      width: 200px;
      margin-top: 10px;
    }
  }
`;

export const Mobile = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: none;
  padding: 10px;
  background: #333;
  z-index: 600;
  @media (max-width: 750px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
