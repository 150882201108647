import styled, { keyframes } from 'styled-components';

const floater = keyframes`
0% {
  transform : translateX(0px) ;
  transform : translateY(0px) ;
}
50%{
  transform : translateX(10px) ;
  transform : translateY(5px) ;
}
100%{
  transform : translateX(0px) ;
  transform : translateY(0px) ;
}
`;

interface IContainer {
  type: string;
}

export const Container = styled.div<IContainer>`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  background: none;
  text-align: center;
  z-index: 300;

  @media (max-width: 750px) {
    position: ${props => (props.type === 'main' ? 'absolute' : 'relative')};
  }

  > a {
    padding-bottom: 20px;
    @media (max-width: 750px) {
      padding-bottom: ${props => (props.type === 'main' ? '100px' : '0px')};
    }
    button.icon {
      cursor: pointer;
      background: none;
      height: 100px;
      width: 100px;
      border: 0px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 80px;
      font-weight: bold;
      color: rgb(88, 41, 93);

      animation: ${floater} 0.7s linear infinite;
      cursor: pointer;

      @media (max-width: 750px) {
        font-size: 60px;
        height: 80px;
        width: 80px;
      }
    }
  }
`;
