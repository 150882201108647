import { date } from './date';

interface IMasks {
  [key: string]: Function;
}

export default function mask(value: string, maskName: string): string {
  const validMask: IMasks = {
    date: (text: string) => date(text),
  };

  return validMask[maskName] ? validMask[maskName](value) : value;
}
