import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { Container, Title, AnimatedDiv } from './styles';
import { ContainerWithBackground } from '../Cases/styles';
import ImageBackground from '../../components/ImageBackgroud';

const Team: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Somos - Inventa Evento</title>
      </Helmet>
      <ContainerWithBackground>
        <div style={{ paddingTop: '200px' }}>
          <Title>Nosso Manifesto</Title>
        </div>
      </ContainerWithBackground>
      <div>
        <div className="perspective-right">
          <ImageBackground background="/assets/obstinados.jpg" />
          <div className="filter" />
        </div>
        <div className="Paragraph primary">
          <h2>Somos Obstinados</h2>
          <p>
            Temos sangue nos olhos e paixão pelo que fazemos. Gostamos de
            adrenalina, nos desafiamos o tempo todo e adoramos não ter rotina.
            Fazemos muitas coisas ao mesmo tempo, somos ágeis e encontramos
            motivação em criar experiências únicas.
          </p>
        </div>
      </div>
      <div>
        <div className="Paragraph secondary">
          <h2>Somos Maestros</h2>
          <p>
            Nosso olhar é estratégico: entregamos o evento de ponta a ponta com
            maestria. Enxergamos o que os outros não veem, identificamos o que é
            prioridade e trabalhamos com regras claras. Somos comprometidos,
            conectados e confiamos em nossa rede.
          </p>
        </div>
        <div className="perspective-left">
          <ImageBackground  position="bottom" background="/cases/14/2.jpg.resized.jpeg" />
          <div className="filter" />
        </div>
      </div>
      <div>
        <div className="perspective-right">
          <ImageBackground background="/assets/somos-unidos.jpg" />
          <div className="filter" />
        </div>
        <div className="Paragraph  primary">
          <h2>Somos Unidos</h2>
          <p>
            Nosso time é diverso e incrivelmente complementar, admiramos uns aos
            outros e evoluímos juntos. Estamos unidos pela vontade de realizar
            todos os eventos do mundo, para o resto da vida!
            <br />
            <br /> Nos reinventamos a cada dia, mas uma coisa é o que mais
            importa: a nossa essência continua a mesma.
          </p>
        </div>
      </div>
      <div>
        <div className="Paragraph secondary">
          <h2>Somos a Inventa Evento</h2>
          <p>
            Proporcionamos experiências, felicidade e energia boa! Geramos
            impacto positivo nas pessoas e somos movidos pela alegria ao ver
            nossos clientes felizes nos eventos.
          </p>
        </div>
        <div className="perspective-left">
          <ImageBackground background="/assets/inventa-evento.jpg" />
          <div className="filter" />
        </div>
      </div>
      <ContainerWithBackground>
        <div style={{ paddingTop: '100px' }} />
      </ContainerWithBackground>
    </Container>
  );
};
export default Team;
