import React from 'react';
import Slider, { ResponsiveObject } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface ISlider {
  autoplay?: boolean;
  speed?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  arrows?: boolean;
  list ?: Array<JSX.Element>;
}

const SliderContainer: React.FC<ISlider> = ({
  autoplay = true,
  speed = 2000,
  slidesToShow = 1,
  slidesToScroll = 1,
  arrows = true,
  list,
}) => {
  const settings = {
    infinite: true,
    autoplay,
    speed,
    slidesToShow,
    slidesToScroll,
    arrows,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return <Slider {...settings}>{list}</Slider>;
};

export default SliderContainer;
