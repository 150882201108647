import styled from 'styled-components';
import { backgrounds } from 'polished';

interface IContainer {
  background: string;
  position ?: string;
}

export const Container = styled.div<IContainer>`
  position: relative;
  width: auto;
  background: url(${props => props.background}) no-repeat ${props => props.position};
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  overflow: hidden;

  @media (max-width: 750px) {
  }
`;
