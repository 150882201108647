import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 1);
  width: 100%;
  top: 0;
  left: 0;
  height: 46px;
  z-index: 100;
  padding: 0px 50px;

  nav {
    cursor: pointer;
    color: white;
    font-size: 16px;
    opacity: 1;
    transition: opacity 0.2s;
    font-weight: bold;

    & + nav {
      margin-left: 50px;
    }

    &:hover {
      opacity: 0.9;
    }
  }
`;
