import React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { scrollTo } from '../Menu';
import { Container } from './styles';

interface IPointer {
  targetID: string;
  speed?: number;
  type?: string;
}

const Scroller: React.FC<IPointer> = ({
  children,
  targetID,
  speed = 1000,
  type = '',
}) => {
  return (
    <Container type={type}>
      {children}
      <Link to="/" onClick={e => scrollTo(e, targetID, speed)}>
        <button className="icon" type="button">
          <FaChevronDown />
        </button>
      </Link>
    </Container>
  );
};

export default Scroller;
