import React, { useEffect, useState } from 'react';

import {
  FaWhatsapp,
  FaTelegram,
  FaInstagram,
  FaFacebookSquare,
  FaHamburger,
  FaSignInAlt,
  FaSignOutAlt,
  FaSign,
} from 'react-icons/fa';

import { MdMenu } from 'react-icons/md';
import TextPopper from '../TextPopper';
import { useAuth } from '../../hooks/Auth';

import {
  Container,
  Navigation,
  NavigationList,
  LinkLi as Link,
  MenuIcon,
  Icon,
  MobileMenuBackground,
  TextMessage,
  IconSet,
} from './styles';
import logo from '../../assets/logo.png';
import Whatsapp from '../Whatsapp';

interface IOffset extends Element {
  offsetTop: number;
}

export function scrollTo(
  e2: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  targetID2: string,
  duration2 = 200,
): void {
  const scrollToTarget = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    targetID: string,
    duration = 200,
  ): void => {
    e.preventDefault();
    const target = document.querySelector<IOffset>(`#${targetID}`);
    const position = target && target.offsetTop ? target.offsetTop : 0;
    const offset = position - 92;

    const startPosition = window.pageYOffset;

    const distance = offset - startPosition;
    let startTime = 0;

    const ease = (t: number, b: number, c: number, d: number): number => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animation = (currentTime = 0): void => {
      if (startTime === 0) {
        startTime = currentTime;
      }

      const timeElapsed = currentTime - startTime;

      const run = ease(timeElapsed, startPosition, distance, duration);

      window.scrollTo(0, run);

      if (timeElapsed < duration) {
        requestAnimationFrame(animation);
      }
    };
    requestAnimationFrame(animation);
  };

  scrollToTarget(e2, targetID2, duration2);
}

const WhatsappIcon: React.FC = () => {
  return (
    <Icon
      style={{ margin: '0px' }}
      background="rgb(0,150,100)"
      title="Whatsapp"
      href="https://api.whatsapp.com/send?l=pt&phone=5551981476007"
    >
      <FaWhatsapp />
    </Icon>
  );
};

const LoginIcons: React.FC = () => {
  const { signOut, token } = useAuth();

  return (
    <li key="login">
      {token ? (
        <Link
          onClick={e => {
            signOut();
          }}
          to="/"
          title="Sair"
        >
          Sair
          <FaSignOutAlt />
        </Link>
      ) : (
        <Link to="/" title="Login">
          Login
{' '}
<FaSignInAlt />
        </Link>
      )}
    </li>
  );
};

interface IIcons {
  message?: string;
  showText?: boolean;
  backgroundFill?: boolean;
}

const ContactIcons: React.FC<IIcons> = ({
  message = '',
  showText = false,
  backgroundFill = true,
}) => {
  return (
    <IconSet column={showText}>
      {message !== '' && (
        <div className="messageSet">
          <TextMessage>{message}</TextMessage>
        </div>
      )}
      <div className="iconSet">
        <Icon
          extend={showText}
          className="socialIcon"
          // background={backgroundFill === true ? 'rgb(0,150,100)' : 'none'}
          background="none"
          title="Whatsapp"
          href={`https://api.whatsapp.com/send?l=pt&phone=555133111101&text=${encodeURI(
            'Oi! Estou no site da Inventa!',
          )}`}
        >
          <FaWhatsapp />

          {showText && <span>Whatsapp</span>}
        </Icon>

        <Icon
          extend={showText}
          className="socialIcon"
          title="Instagram"
          href="https://www.instagram.com/inventaevento"
          background="none"
          // background={backgroundFill === true ? 'rgb(250,0,100)' : 'none'}
        >
          <FaInstagram />
{' '}
{showText && <span>Instagram</span>}
        </Icon>
        <Icon
          extend={showText}
          className="socialIcon"
          title="Facebook"
          href="https://www.facebook.com/InventaEvento"
          background="none"
          // background={backgroundFill === true ? '#3d6ad6' : 'none'}
        >
          <FaFacebookSquare /> {showText && <span>Facebook</span>}
        </Icon>
      </div>
    </IconSet>
  );
};

interface IMenu {
  menuList: Record<string, any>;
  fixedMenu: boolean;
}

const Menu: React.FC<IMenu> = ({ menuList, fixedMenu = true }) => {
  const [sticky, setSticky] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);

  useEffect(() => {
    if (fixedMenu) {
      const header = document.querySelector<IOffset>('.menuHeader');

      const offsetValue = header?.offsetTop || 0;

      const offsetMonitor = (): void => {
        if (window.pageYOffset > offsetValue) {
          setSticky(true);
        } else {
          setSticky(false);
        }
      };

      window.onscroll = () => {
        offsetMonitor();
      };
    }
  }, [fixedMenu, sticky]);

  const handleActiveMenu = (): void => {
    setActiveMenu(!activeMenu);
  };

  return (
    <Container
      sticky={sticky}
      fixedMenu={fixedMenu}
      activeMenu={activeMenu}
      className="menuHeader"
    >
      <Navigation sticky={sticky} fixedMenu={fixedMenu}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {' '}
          <Link
            to="/"
            onClick={e => {
              if (window.location.pathname === '/') {
                scrollTo(e, 'root');
              } else {
                setActiveMenu(false);
              }
            }}
          >
            <img title="Inventa Evento" alt="Inventa Evento" src={logo} />
          </Link>
          <div className="onlyComputer">
            <Link to="/manifesto">
              <TextPopper
                group
                direction="fade"
                messages={[
                  'Somos obstinados, ',
                  'Somos maestros, ',
                  'Somos unidos, ',
                  'Somos a Inventa Evento!',
                ]}
                time={1500}
              />
            </Link>
          </div>
        </div>
        <MobileMenuBackground activeMenu={activeMenu} />
        <NavigationList activeMenu={activeMenu}>
          {menuList.map((menu: Record<string, any>) => {
            const { title, url, icon, targetID = null, fluid = false } = menu;

            const LinkMe: React.FC = () => {
              if (fluid) {
                return (
                  <Link
                    onClick={e => {
                      if(window.location.pathname !== '/'){
                        window.location.href = `/#${targetID}`;
                      }
                      else{
                      scrollTo(e, targetID);
                      setActiveMenu(false);
                      }
                    }}
                    to={url}
                    title={title}
                  >
                    {icon}
                    {title}
                  </Link>
                );
              }

              return (
                <Link
                  title={title}
                  to={url}
                  onClick={() => {  setActiveMenu(false);}}
                >
                  {icon}

                  {title}
                </Link>
              );
            };

            return (
              <li key={url}>
                <LinkMe />
              </li>
            );
          })}

          <li className="closeMenu" onClick={() => handleActiveMenu()}>
            {'<<'}
            Voltar
          </li>

          <div className="row">
            <ContactIcons backgroundFill={false} />
          </div>
        </NavigationList>

        <MenuIcon onClick={() => handleActiveMenu()}>
          <MdMenu size="40px" />
        </MenuIcon>
      </Navigation>
    </Container>
  );
};

export { WhatsappIcon, ContactIcons, Menu };
