import axios, { AxiosInstance } from 'axios';

class Api {
  public api: AxiosInstance;

  constructor() {
    const token = localStorage.getItem('@');

    this.api = axios.create({
      baseURL: '',
    });

    if (token) {
      this.api.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }
}

export default new Api().api;
