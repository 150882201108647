import React, { useState, createContext, useContext, useCallback } from 'react';
import { uuid } from 'uuidv4';
import api from '../services/api';
import mask from '../utils/mask';

interface IObject {
  [key: string]: string;
}

interface ISearchOrder {
  orderBy: string;
  direction?: 'asc' | 'desc';
}

export interface ISearchContainer {
  id: string;
  endpoint: string;
  search?: string;
  order?: ISearchOrder;
  where?: Record<string, any>;
  schema: Record<string, Record<string, any>>;
}

export interface ISearchRows {
  [key: string]: Record<string, any>;
}

interface ISearchContextData {
  addSearch(key: string, config: ISearchContainer): void;
  removeSearch(key: string): void;
  reloadSearch(key: string): Promise<void>;
  reloadSearchAll(): Promise<void>;
  changeSearchWhere(key: string, where: Record<string, any>): Promise<void>;
  changeSearchOrder(
    key: string,
    order: string,
    direction: string,
  ): Promise<void>;
  changeSearchText(key: string, text: string): Promise<void>;
  dataRows: ISearchRows;
  containers: Array<ISearchContainer>;
}

const SearchContext = createContext<ISearchContextData>(
  {} as ISearchContextData,
);

const SearchProvider: React.FC = ({ children }) => {
  const [containers, setContainers] = useState<Array<ISearchContainer>>([]);

  const [dataRows, setDataRows] = useState<ISearchRows>({});

  const prepareKey = useCallback(() => uuid(), []);

  const addSearch = useCallback(
    (
      key,
      {
        id,
        endpoint,
        order = { orderBy: 'id', direction: 'desc' },
        where = {},
        search = '',
        schema,
      }: ISearchContainer,
    ): string => {
      setContainers(state => [
        ...state,
        {
          id,
          endpoint,
          order,
          where,
          search,
          schema,
        },
      ]);

      setDataRows(stateRow => ({ ...stateRow, [id]: [] }));

      return id;
    },
    [],
  );

  const removeSearch = useCallback(
    async (id: string) => {
      await setContainers(
        containers.filter((container: ISearchContainer) => container.id !== id),
      );
    },
    [containers],
  );

  const reloadSearch = useCallback(
    async (id: string) => {
      const replaceContainers = [...containers];

      const index = replaceContainers.findIndex(
        (container: ISearchContainer) => container.id === id,
      );

      if (index >= 0) {
        const { endpoint, search, order, where, schema } = replaceContainers[
          index
        ];

        const params = { search: search || '', order, where };
        const response = await api.get(endpoint, { params });

        const display = response.data.reduce(
          (prev: Array<Record<string, any>>, items: IObject) => {
            const elem: IObject = {};
            Object.keys(schema).map(key => {
              elem[schema[key].column] = schema[key].mask
                ? mask(items[schema[key].column], schema[key].mask || '')
                : items[schema[key].column];
            });

            prev = [...prev, elem];
            return prev;
          },
          [],
        );

        await setDataRows(state => ({ ...state, [id]: display }));
      }
    },
    [containers],
  );

  const reloadSearchAll = useCallback(async () => {
    Promise.all(containers.map(container => reloadSearch(container.id)));
  }, [containers, reloadSearch]);

  const changeSearchText = useCallback(
    async (id, text) => {
      const replaceContainers = [...containers];

      const index = replaceContainers.findIndex(
        container => container.id === id,
      );

      replaceContainers[index].search = text;

      setContainers([...replaceContainers]);
      await reloadSearch(id);
    },
    [containers, reloadSearch],
  );

  const changeSearchOrder = useCallback(
    async (id, order, direction) => {
      const replaceContainers = [...containers];

      const index = replaceContainers.findIndex(
        container => container.id === id,
      );

      if (
        replaceContainers[index]?.order &&
        replaceContainers?.[index]?.order?.orderBy === order
      ) {
        direction =
          replaceContainers?.[index]?.order?.direction === 'asc' ? 'desc' : 'asc';
      }

      replaceContainers[index].order = { orderBy: order, direction };

      setContainers([...replaceContainers]);
      await reloadSearch(id);
    },
    [containers, reloadSearch],
  );

  const changeSearchWhere = useCallback(
    async (id, where) => {
      const replaceContainers = [...containers];

      const index = replaceContainers.findIndex(
        container => container.id === id,
      );

      replaceContainers[index].where = where;

      setContainers([...replaceContainers]);
      await reloadSearch(id);
    },
    [containers, reloadSearch],
  );

  return (
    <SearchContext.Provider
      value={{
        addSearch,
        removeSearch,
        reloadSearch,
        reloadSearchAll,
        changeSearchOrder,
        changeSearchText,
        changeSearchWhere,
        dataRows,
        containers,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

function useSearch(): ISearchContextData {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }

  return context;
}

export { useSearch, SearchProvider };
