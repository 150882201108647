import React from 'react';
import { Wrapper, Background, Footer, Mobile } from './styles';
import { Pattern } from '../../../styles/ThemeComponents';
import Header from '../../../components/Header';
import { Menu, ContactIcons } from '../../../components/Menu';
import { Title } from '../../Cases/styles';

const menuList = [
  {
    title: 'Somos',
    url: '/manifesto',
    icon: '',
    fluid: false,
    targetID: '',
  },
  {
    title: 'Cases',
    url: '/',
    icon: '',
    fluid: true,
    targetID: 'cases2',
  },

];

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <>
      <Wrapper>
        <Menu menuList={menuList} fixedMenu />
        {children}
        <Pattern />
        <Background />
      </Wrapper>
      <Footer style={{background: 'rgba(0,0,0,0.9)'}}>
     <div style={{width: '100%'}}><Title> GOSTOU? LIGA PRA GENTE!</Title>
     <Title style={{fontSize: '36px'}}>51 98152-0254</Title>
     <Title style={{fontSize: '36px'}}>51 3311-1101</Title></div></Footer>
      <Footer>
        <div>
          Av. Mariland, 907/403
          <br />
          CEP 90440-191
          <br />
          Porto Alegre/RS
        </div>
        <div>
          <ContactIcons showText />
        </div>
        <div>
          Associado
          <br />
          <img src="/assets/arp.png" alt="ARP" />
        </div>
      </Footer>
      <Mobile>
        <ContactIcons backgroundFill={false} />
      </Mobile>
    </>
  );
};

export default DefaultLayout;
