import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  right: 0;
  top: 0;
  padding: 0px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 1000;
`;
