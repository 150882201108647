import React from 'react';

import { AuthProvider } from './Auth';
import { ToastProvider } from './Toast';

import { ModalProvider } from './Modal';
import { SearchProvider } from './Search';

const AppProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <ToastProvider>
        <ModalProvider>
          <SearchProvider>{children}</SearchProvider>
        </ModalProvider>
      </ToastProvider>
    </AuthProvider>
  );
};

export default AppProvider;
