import React, { useCallback, useRef } from 'react';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory, useParams } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.jpg';

import {casesList} from './cases';

import { FaChevronDown } from 'react-icons/fa';

import {
  Container,
  Content,
  Background,
  AnimatedDiv,
  CaseCard,
  CaseCardsContainer,
  CaseContainer,
  Title,
  Photo,
  Wrapper,
} from './styles';

import getValidationErrors from '../../utils/getValidationErrors';
import { ContactIcons, scrollTo } from '../../components/Menu';

import TextPopper from '../../components/TextPopper';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import Vimeo from '../../components/Vimeo';

import { Pattern } from '../../styles/ThemeComponents';
import VideoBackground from '../../components/VideoBackground';
import SliderContainer from '../../components/Slider';
import Scroller from '../../components/Scroller';
import CasesSlider from '../CasesSlider';
import { Helmet } from 'react-helmet';

const video = '/assets/caseSuergs2019.mp4';
const videoThumb = '/assets/caseSuergs2019-thumb.jpg';

interface CasesData {
  email: string;
  password: string;
}

interface ICase {
  case : string;
}

const Cases: React.FC = () => {
  const  {case : caseUrl } = useParams<ICase>();

  const caseIndex = casesList.findIndex( c => c.link_url === caseUrl);

  const caseData = casesList[caseIndex];

  const caseList : Array<JSX.Element> = casesList?.[caseIndex]?.photos?.reduce( ( prev,item) => {

      prev.push(<Photo background={item.url} />);

      return prev;


  },[] as Array<JSX.Element>  )

  const history = useHistory();
  const { signIn } = useAuth();
  const { addToast } = useToast(); 
  const formRef = useRef<FormHandles>(null);
  const handleSubmit = useCallback(
    async (data: CasesData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Email obrigatório')
            .email('Preencha um email válido'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        const { email, password } = data;

        history.push('/dashboard');
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description: 'Ocorreu um erro ao fazer login',
        });
      }
    },
    [Cases, addToast, history],
  );

  return (
    <Wrapper>
      <Helmet>
        <title>{caseData.title}</title>
        
      </Helmet>

      <Container id="fotos">
        <div className="p50">
          <div className="size400">
            <label>{caseData.client}</label>
            <Title>{caseData.title}</Title>

            <p dangerouslySetInnerHTML={{__html : caseData.description}}/>
          
          </div>
        </div>
        <div className="p50 secondary">
          <SliderContainer list={caseList}>
          
         
          </SliderContainer>
        </div>

        {caseData.videos && caseData.videos.length > 0 &&  <Scroller targetID="video" />}
      </Container>

      <div
        style={{
          width: '100%',
          padding: '40px 20px',
          background: 'rgba(0,0,0,0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
     
      </div>
{caseData.videos && caseData.videos.length > 0 &&
      <Container style={{justifyContent: 'center'}} id="video">
  {caseData.videos.map( video => (
    <div className="p50">
    <div className="videoContainer">
      <Vimeo id={video.video_url} />
    </div>
  </div>

  ))}
    

        <Scroller targetID="cases" />
      </Container>
      }
     <CasesSlider ignore={caseUrl}/>
    </Wrapper>
  );
};
export default Cases;
