import React, { useState, useEffect } from 'react';

import { AnimatedDiv } from './styles';

interface ITextPopperProps {
  messages: Array<string>;
  time: number;
  group?: boolean;
  direction?: 'up' | 'down' | 'left' | 'right' | 'fade';
}

const TextPopper: React.FC<ITextPopperProps> = ({
  messages,
  time = 1000,
  group = false,
  direction = 'down',
}) => {
  const [singleText, setSingleText] = useState<Array<string>>(
    [messages[0]] || [],
  );

  useEffect(() => {
    const length = messages.length - 1;
    let x = 1;

    setInterval(() => {
      if (x === 0) {
        setSingleText([messages[x]]);
      } else if (group) {
        setSingleText(state => [...state, messages[x]]);
      } else {
        setSingleText([messages[x]]);
      }

      if (x === length) {
        x = 0;
      } else {
        x += 1;
      }
    }, time);
  }, []);

  const Ani: React.FC = () => {
    const size = singleText.length - 1;
    return (
      <>
        {singleText.map((single, index) =>
          size === index ? (
            <AnimatedDiv direction={direction} key={single}>
              {single}
            </AnimatedDiv>
          ) : (
            <span key={single}>{single}</span>
          ),
        )}
      </>
    );
  };

  return <Ani />;
};

export default TextPopper;
