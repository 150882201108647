import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './routes';

import Cases from '../pages/Cases';
import Manifest from '../pages/Manifest';
import CasesV1 from '../pages/CasesV1';
import Team from '../pages/Team';
import { CasesGrid } from '../pages/CasesSlider';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Cases} />
      <Route path="/cases" exact component={CasesGrid} />
      <Route path="/cases/:case" component={CasesV1} />

      <Route path="/manifesto" component={Manifest} />
    </Switch>
  );
};

export default Routes;
