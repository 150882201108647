const unimed = {
    client : 'Unimed Federação',
    title : 'SUERGS2019 e Forum Instituto Unimed/RS',
    title_capa:'SUERGS e Forum Instituto Unimed/RS',
    year : '2019',
    description: `
    Entre os dias 15 e 17 de maio de 2019, a Unimed Federação/RS realizou dois de seus principais eventos: o Simpósio da Unimed do Estado do Rio Grande do Sul (Suergs 2019) e o Fórum Instituto Unimed/RS - com o tema "saúde, diversidade e futuro". Os encontros aconteceram de forma presencial no Theatro São Pedro, em Porto Alegre. 
<br/><br/>O Suergs é um evento de cunho técnico, voltado a dirigentes e lideranças do Sistema Unimed-RS. O Fórum Instituto Unimed/RS, evento gratuito e aberto à comunidade, chegou à sua 10ª edição.
<br/><br/>
Fotos créditos: © Mathias Cramer/temporealfoto.`,
    link_url: 'suergs-2019',
    capa : '/cases/1/2.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/1/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/1/3.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/1/4.png'  },
        { title : '',  url : '/cases/1/5.png'  },
        { title : '',  url : '/cases/1/6.png'  },
        { title : '',  url : '/cases/1/8.png'  },
    ],
    videos : [
        { 
        type : 'vimeo',
        title : '',
        video_url : '427126612',
        }
    ]

}

const GPRS = {
    client : 'GPRS',
    title : 'Congresso de Planejamento 2019',
    title_capa : 'Congresso de Planejamento',
    year : '2019',
    description: `
    No dia 24 de maio de 2019, o GPRS – Grupo de Planejamento do RS - realizou a quarta edição do Congresso de Planejamento. Pauta voltada para ampliar a visão sobre a estratégia de marca, gerando assim mais consistência e contribuindo para que o mercado se sinta mais preparado e provocado pelo tema. 
<br/><br/>Fotos créditos: @glaucoarnt
`,
    link_url: 'gprs-2019',
    capa : '/cases/2/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/2/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/2/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/2/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/2/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/2/5.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/2/6.jpg.resized.jpeg'   },
    ],
    videos : [
       
    ]

}

const RENNER = {
    client : 'Lojas Renner',
    title : 'Renner no Planeta Atlântida 2020',
    title_capa : 'Renner no Planeta Atlântida',
    year : '2020',
    description: `
    Com o objetivo de explorar o tema sustentabilidade de forma relevante e consistente para o público jovem, criamos o Planeta Re, com o tema O PLANETA FEITO POR MIM. Aqui mostramos o planetário como protagonista do festival e também do planeta Terra. Através desse conceito levamos a mensagem nas ações e na cenografia dos espaços Renner dentro do maior festival de música do RS.
    <br/><br/> O camarote Renner com cenografia de reaproveitamento recebeu dezenas de convidados que curtiram as ações e foram recebidos com coquetel feito de produtos locais e orgânicos. Todos ganharam um Copo Eco para não usarem copos descartáveis.
    <br/><br/>  Na Arena, uma das atrações foi a roda gigante com um mirante e vista privilegiada para o festival. Também tivemos o espaço com uma batalha de bikes que gerava energia solar para carregar bateria de celular dos planetários. Os participantes ganhavam uma pochete feita por mulheres de associações de costureiras da comunidade Bom Jesus com tecidos de re-uso
    <br/><br/>  Toda a produção do evento e participação dos convidados foi convertida em neutralização de carbono, contribuindo assim para um planeta mais responsável.
    <br/><br/>
    Credito fotos: @fabianopanizzi
    
`,
    link_url: 'renner-planeta-atlantida-2020',
    capa : '/cases/3/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/3/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/3/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/3/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/3/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/3/5.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/3/6.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/3/7.jpg.resized.jpeg'   },
    ],
    videos : [
       
    ]

}

const RENNERInauguracao = {
    client : 'Lojas Renner',
    year : '2017',
    title : 'Inauguração Primeira Renner no Uruguai',
    title_capa:'',
    description: `
    2017 foi um marco para a Lojas Renner,  o ano da inauguração da primeira loja em território estrangeiro, na tradicional Av. 18 de Julio, em Montevidéu. A chegada da Renner no Uruguai mereceu um evento especial de inauguração. 
<br/><br/>Na noite anterior a abertura oficial, realizamos um evento pré-opening para um grupo de 300 convidados. Entre eles influenciadores digitais, imprensa e autoridades públicas. Além do descerramento da placa de inauguração, o momento foi comemorado com um coquetel ao som da DJ Vala Niremberg e uma iluminação especial na fachada valorizando o icônico prédio do antigo cinema Trocadero.
<br/><br/>
Crédito fotos : @pablomartinezpessi 
`,
    link_url: 'renner-inauguracao',
    capa : '/cases/4/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/4/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/4/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/4/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/4/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/4/5.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/4/6.jpg.resized.jpeg'   },

    ],
    videos : [
       
    ]

}


const JARDIMSTIHL = {
    client : 'STIHL',
    year : '2017',
    title : 'Jardim das Ideias STIHL',
    title_capa:'',
    description: `
    Projeto que iniciou em 2017, teve sua primeira edição na Floricultura Winge em Porto Alegre. O evento reuniu mais de 200 pessoas entre clientes da floricultura e público interessado em jardinagem, cultivo e terráreos. 
   <br/><br/> O evento contou com <strong>workshops sobre paisagismo, palestras da paisagista Gabriela Pileggi do GNT</strong>, espaço promocional cenográfico com exposição e destaque para os produtos STIHL (pulverizadores, lavadoras, sopradores, roçadores, entre outros), distribuição de mini suculentas aos participantes, montagem de área promocional de experimentação dos produtos da marca e espaço de recreação para o público infantil. O projeto ganhou edições em diversas cidades do Brasil.
`,
    link_url: 'jardim-das-ideias-stihl',
    capa : '/cases/5/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/5/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/5/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/5/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/5/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/5/5.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/5/6.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/5/7.jpg.resized.jpeg'   },


    ],
    videos : [
       
    ]

}

const BOEHRINGER = {
    client : 'Boehringer',
    year : '2019',
    title : 'Casa Boehringer Expointer 2019',
    title_capa : 'Casa Boehringer Expointer',
    description: `
    A Casa Boehringer na Expointer já é um ponto de encontro de clientes e parceiros da marca. O espaço todo pensado no conforto e no bem-estar dos convidados, contou com uma programação bem gauchesca. Durante os nove dias da feira, a casa recebeu em torno de 150 convidados por dia que, além de participarem de rodadas de negócios, desfrutaram de cafés, almoços e jantares especiais. Dentro da programação, ainda aconteceu um show típico com o artista gaúcho Joca Martins e foi servida uma tradicional paella campeira.
`,
    link_url: 'casa-boehringer',
    capa : '/cases/6/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/6/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/6/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/6/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/6/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/6/5.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/6/6.jpg.resized.jpeg'   },

    ],
    videos : [
       
    ]

}

const ARP = {
    client : 'ARP – Associação Riograndense de Propaganda',
    year : '2019',
    title : 'Salão ARP 2019 / Park Shopping Canoas',
    title_capa:'Salão ARP / Park Shopping Canoas',
    description: `
    O Salão ARP premia empresas e profissionais da comunicação que se destacaram ao longo do ano. Com o passar do tempo, o evento vem se reinventando, e em 2018, passou por grandes mudanças: ganhou novo nome, formato e um clima mais descontraído e dinâmico. 
<br/><br/>Antes Salão da Propaganda, o agora Salão ARP é dividido em dois momentos: premiação e a festa.
<br/><br/>A inventa organiza o evento há mais de uma década.
<br/><br/>Projeto: nowhere / Rito Entretenimento
<br/><br/>
Fotos créditos: ©gluacoarnt
`,
    link_url: 'salao-arp-2019',
    capa : '/cases/7/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/7/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/7/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/7/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/7/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/7/5.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/7/6.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/7/7.jpg.resized.jpeg'   },
    ],
    videos : [
       
    ]

}

const Youcom = {
    client : 'Youcom',
    year : '2020',
    title : 'E Se Essa Rua Fosse Sua?',
    title_capa:'',
    description: `
    Moda, cultura, arte, empreendedorismo e muita diversão. Assim foi o super evento  “E Se Essa Rua Fosse Sua?”que aconteceu no centro de São Paulo, na Rua XV de Novembro. O evento teve como objetivo criar experiências e relacionamento com o público mostrando o discurso da marca e estabelecendo uma relação de co-criação com o jovem.
<br/><br/>A programação contou com espaços e apresentações de sete coletivos, oito foodtruks, um espaço Jeans For Change da Youcom, onde foram arrecadadas quase 2.000 peças jeans para o projeto, e claro, muita música com artistas como Dingo Bells, Karol Conka, Selvagem, entre outros.
<br/><br/>Fotos: Youcom
`,
    link_url: 'youcom-se-essa-rua-fosse-sua',
    capa : '/cases/8/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/8/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/8/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/8/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/8/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/8/5.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/8/6.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/8/7.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/8/8.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/8/9.jpg.resized.jpeg'   },
    ],
    videos : [
       
    ]

}

const ABICALCADOS = {
    client : 'Abicalçados',
    year : '2018',
    title : 'Maratona MMX 2018',
    title_capa: 'Maratona MMX',
    description: `
    A MMX é promovida pela Abicalçados. Sob as temáticas de inovação, tecnologia e negócios para moda e design de calçados, o evento é uma maratona para o futuro do calçado e contou com a tradicional batalha criativa, um hackathon de soluções de tecnologia para o setor e muito conteúdo e experiências com diversos speakers. E para fechar o evento, a participação especial do Art Battle Sul.
`,
    link_url: 'maratona-mmx-2018',
    capa : '/cases/9/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/9/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/9/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/9/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/9/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/9/5.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/9/6.jpg.resized.jpeg'   },

    ],
    videos : [
       
    ]

}

const cocacolashoes = {
    client : 'Morya/Coca-Cola Shoes',
    year : '2018',
    title : 'Convenção Coca-Cola Shoes',
    title_capa:'',
    description: `
    Realizada na Casa Joá no verão de 2020, no Rio de Janeiro, a Convenção Coca-Cola Shoes recebeu lojistas e representantes da marca para uma imersão na nova coleção. A cenografia e as experiências foram baseadas no tema “MUNDO”, além de levar a cor e elementos da marca. Em uma das áreas externas com vista para a pedra da Gávea, foi instalada uma cobertura climatizada, neste espaço foi realizada a convenção, desfile e show. Já no rooftoop, o ambiente foi preparado para receber o coquetel de encerramento. Os convidados ganharam um chinelo Slide customizado pelo artista carioca, Fredy Nascimento.
<br/><br/>
Fotos: I Hate Flash – Bléia Campos
`,
    link_url: 'coca-cola-shoes',
    capa : '/cases/10/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/10/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/10/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/10/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/10/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/10/5.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/10/6.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/10/7.jpg.resized.jpeg'   },
    ],
    videos : [
       
    ]

}

const corridaGM = {
    client : 'GM',
    year : '2020',
    title : 'Chevrolet Run 19 – Etapa CIAG',
    title_capa:'',
    description: `
    Aconteceu no Complexo Automotivo de Gravataí no dia 03 de novembro de 2019, nas categorias feminino e masculino para público CIAG e público geral. Corrida de 5km e 10km em caráter competitivo, caminhada de 3km em caráter participativo.
<br/><br/>O percurso foi dentro do complexo automotivo e ainda passou por dentro da Fábrica da GM.
<br/><br/>A Inventa Evento em parceria com o CORPA - Clube dos Corredores de Porto Alegre, cuidou de todo a organização e produção da prova que reuniu muitos funcionários e suas famílias em um dia mágico.
<br/><br/>A corrida contou com inúmeras atrações, como exposição de carros, distribuição de frutas e barras de cereais, distribuição de água durante todo o percurso, alongamento, área kids com brinquedos, chimarródramo, serviço de bioimpedância oferecido pelo SESI/SENAI e duas utis móveis com posto médico.
<br/><br/>Foi um evento incrível, agora vamos correr para a próxima etapa!
<br/><br/>
Fotos: Marcio Rodrigues
`,
    link_url: 'corrida-gm',
    capa : '/cases/11/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/11/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/11/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/11/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/11/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/11/5.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/11/6.jpg.resized.jpeg'   },
    
    ],
    videos : [
        { 
            type : 'vimeo',
            title : '',
            video_url : '503286578',
            }
        
    ]

}


const Kopstein = {
    client : 'Kopstein / Global',
    year : '2020',
    title : 'Exposição De A a Z: O Mundo de Isay Weinfeld',
    title_capa:'',
    description: `
    A Mostra no Instituto Ling celebrou a obra do renomado arquiteto paulista, Isay Weinfeld, com 24 obras – maquetes, filmes, mobiliário e objetos que relembraram a trajetória do arquiteto, como o restaurante Four Seasons, em Nova York, e o edifício La Petitte Afrique, em Mônaco. 
<br/><br/>O evento de lançamento, recebeu aproximadamente 300 convidados em uma noite intimista e com bate-papo com o arquiteto, mediado por Anthony Ling explorou a vida e a obra do homenageado. Weinfeld também apresentou o lançamento do empreendimento Varanda, da incorporadora Kopstein, segundo trabalho com a assinatura do arquiteto em Porto Alegre. 
<br/><br/>Com exclusividade, a trilha sonora do evento foi criada pelo publicitário Eduardo Axelrud. O cardápio da noite teve assinatura do Chef Lucio, regado com Möet & Chandon e ambientação da Daniela Lomba  integrada à arquitetura de Isay Weinfeld.

<br/><br/>Fotos: @glaucoarnt

`,
    link_url: 'kopstein-global-o-mundo-de-isay-weinfeld',
    capa : '/cases/12/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/12/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/12/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/12/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/12/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/12/5.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/12/6.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/12/7.jpg.resized.jpeg'   },
    ],
    videos : [
    
        
    ]

}

const Tramontina = {
    client : 'Tramontina',
    year : '2019',
    title_capa:'',
    title : 'SP GASTRONOMIA - Maior festival de cultura alimentar do Brasil',
    description: `
    Tramontina é patrocinadora ouro do festival Mesa SP no Memorial da América Latina. O evento faz parte do projeto SP Gastronomia, do Governo do Estado de São Paulo. A marca é responsável pelas cozinhas e equipamentos usados pelos chefs e professores durante o festival. Também instalou um espaço instagramável no Memorial da América Latina, a ativação estimulou os sentidos dos visitantes com elementos do universo da cozinha, como o som de um alimento sendo cortado e o som da manteiga sendo derretida. Além disso, criamos um showroom para vender produtos com mix pensado para o evento.
<br/><br/>Parceiros: Divisão de Efêmeros e Wunderman Thompson
<br/><br/>Fotos: Divisão de Efêmeros


`,
    link_url: 'tramontina-sp-gastronomia-2019',
    capa : '/cases/13/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/13/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/13/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/13/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/13/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/13/5.jpg.resized.jpeg'   },
     
    ],
    videos : [
    
        
    ]

}

const Engage = {
    client : 'Engage/ DC Set',
    year : '2014',
    title : 'Reinauguração Estádio Beira Rio - Festa Gigante',
    title_capa:'',
    description: `
    Um projeto imersivo que envolveu milhares de torcedores através de ações criadas pela “Histórias Incríveis”,  para emocionar os colorados de todo o Rio Grande, Brasil e do mundo. Após dois anos de reforma, a casa do Internacional reabriu em clima de uma grande festa – celebrando a conquista de um estádio totalmente novo e foi sede do maior campeonato do futebol mundial – a Copa do Mundo de 2014.
<br/><br/>A inventa participou com toda produção local do show.  

<br/><br/>Fotos: Jefferson Bernardes


`,
    link_url: 'engage-dc-set-reunauguracao-beira-rio',
    capa : '/cases/14/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/14/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/14/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/14/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/14/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/14/5.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/14/6.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/14/7.jpg.resized.jpeg'   },
    ],
    videos : [
    
        
    ]

}

const Gremio = {
    client : 'DC Set',
    year : '2012',
    title_capa:'',
    title : 'Inauguração Arena do Grêmio',
    description: `
    O evento de inauguração da Arena do Grêmio fez a ligação do passado com o futuro do clube, do nativismo gaúcho, com a modernidade e a nacionalização. Em analogia ao que o clube quer para o futuro: não abandonar a tradição, mas entrar em uma nova era de crescimento, o espetáculo arrancou aplausos dos aficionados.
<br/><br/>Em parceira com a Dcset a inventa fez a produção local de todo o evento



`,
    link_url: 'dc-set-inauguracao-arena-gremio',
    capa : '/cases/15/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/15/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/15/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/15/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/15/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/15/5.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/15/6.jpg.resized.jpeg'   },
       
    ],
    videos : [
    
        
    ]

}

const SouzaCruz = {
    client : 'Souza Cruz',
    year : '2019',
    title_capa:'',
    title : 'Estande Itaipu Rural Show',
    description: `
    Com um projeto referência, a Souza Cruz marcou presença pela primeira vez na 21º Feira Itaipu Rural Show em Pinhalzinho/ SC, o maior evento de agronegócio do estado catarinense. Em um espaço de 300m², a marca recebeu os produtores que buscavam informações do tabaco, da diversificação de culturas e também da inovação no campo. O ambiente com sala de projeção e tela de 180°, possibilitou aos visitantes vivenciarem a história de uma família de agricultores onde a narrativa foca na importância da sucessão familiar e na gestão profissional da propriedade rural. 
 <br/><br/>
A participação marca também o encerramento das comemorações do centenário do Sistema Integrado de Produção de Tabaco, criado pela Souza Cruz em 1918.
<br/><br/>
Com o projeto, em cocriação com a Inventa Evento, a Souza Cruz se destacou em conteúdo e inovação nas Feiras Expoagro (Rio Pardo/RS) e Itaipu (Pinhalzinho/SC).
<br/><br/>
Fotos créditos: ©eduardopeixoto




`,
    link_url: 'souza-cruz-itaupu-rural-show',
    capa : '/cases/16/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/16/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/16/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/16/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/16/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/16/5.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/16/6.jpg.resized.jpeg'   },
       
    ],
    videos : [
    
        
    ]

}


const Camicado = {
    client : 'Camicado',
    year : '2019',
    title : 'Casas do Brasil Camicado',
    title_capa:'',
    description: `
    O projeto Casas do Brasil Camicado proporcionou a 12 clientes com acompanhante e influenciadores, um final de semana cheio de inspiração em casas temáticas distribuídas nas cinco regiões do Brasil (Casa Étnica em Manaus/AM, Casa Praia em Camaçari/BA, Casa Serra em Canela/RS, Casa Fazenda em Campo Grande/MS e Casa Cosmopolita em São Paulo/SP).
<br/><br/>Os clientes tiveram uma conexão muito próxima, uma vivência real com os produtos da Camicado. Todas as casas foram decoradas e assinadas por arquitetos brasileiros.





`,
    link_url: 'camicado-casas-do-brasil',
    capa : '/cases/17/1.jpg.resized.jpeg',
    photos : [
        { title : '',  url : '/cases/17/1.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/17/2.jpg.resized.jpeg'  },
        { title : '',  url : '/cases/17/3.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/17/4.jpg.resized.jpeg'   },
        { title : '',  url : '/cases/17/5.jpeg'   },
    
       
    ],
    videos : [
    
        
    ]

}

export const casesList = [
    
       unimed,
       GPRS,
       RENNER,
       RENNERInauguracao,
       JARDIMSTIHL,
       BOEHRINGER,
       ARP,
       Youcom,
       ABICALCADOS,
       cocacolashoes,
       corridaGM,
       Kopstein,
       Tramontina,
       Engage,
       Gremio,
       SouzaCruz,
       Camicado,
];