import styled from 'styled-components';
import { backgrounds } from 'polished';

interface IContainer {
  background: string;
}

export const Container = styled.div<IContainer>`
  position: relative;
  width: auto;
  height: 100vh;
  background: rgb(65, 203, 192);
  max-height: 100vh;
  overflow: hidden;

  video,
  img,
  >aside {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: auto;
    z-index: 1;
    opacity: 1;width: 100%;
  }

  img {
    z-index: 0;

  }

  >aside {
    z-index: 2;
    opacity: 0.5;
    width: 100%;
    background : ${props => props.background || '#333'};
  }
  @media (max-width: 730px) {
    video,
    img
     {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      width:auto;
      min-height: 100vh;
   
      transform: translateX(-50%);
      z-index: 1;
    }

    img {
      z-index: 0;
    }

    aside {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;

      height: 100%;
      z-index: 2;
      opacity: 0.5;
      width: 100%;
  }
    }
  }

  >div {
    z-index: 100;
  }
`;
