import { createGlobalStyle, keyframes } from 'styled-components';
import { ThemeDefinitions } from './ThemeComponents';

const fadeFromBottom = keyframes`
from {
  opacity: 0;
  transform: translateY(50px);
  filter: blur(4px);
}
to{
  opacity: 1;
  transform:translateY(0);
  filter: blur(0px);
}
`;

export default createGlobalStyle`

iframe {
  z-index: -1 !important;
  display: none;
}

#root{
  iframe {
  z-index:1 !important;
  display: block ;
}
}

@font-face {
    font-family: 'Titillium Web', sans-serif;
}

@font-face {
    font-family: ClanOT;
    src: url("/fonts/clanot-book.otf") format("opentype");
}

@font-face {
    font-family: ClanOT;
    font-weight: bold;
    src: url("/fonts/clanot-bold.otf") format("opentype");
}

@font-face {
    font-family: Phenon;
    src: url("/fonts/phenon-regular.otf") format("opentype");
}

@font-face {
    font-family: Phenon;
    font-weight: bold;
    src: url("/fonts/phenon-bold.otf") format("opentype");
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0
}

body {
  background: ${ThemeDefinitions.bodyBackground};
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

body, input, button{
  font-family: ClanOT, Arial, Helvetica, sans-serif;
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6, strong{
  font-family: 'Phenon', Arial, Helvetica, sans-serif;
font-weight: bold;
}

span, p, label, div, aside, header, footer, span {
  font-family: 'Titillium Web', Arial, Helvetica, sans-serif;
}

button{
  cursor: pointer;
}

div {
  width: 100%;
}

.defaultButton{
  padding: 15px;

  border-radius: 8px;
  border: 0px;
  transition: 0.5s all;
  font-size: 20px;
  background: rgb(65,193,202);
   color: rgb(88,41,93);

  &:hover{
    background: rgba(88,41,93,0.9);
  color: #fff;
  }
}
`;
