import styled from 'styled-components';
import { fadeFromBottom } from '../../components/AnimatedDiv/styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  flex-direction: column;

  position: relative;
  padding: 0px;

  @media (max-width: 750px) {
    flex-wrap: wrap;
    padding: 0px 0px;
  }
  > div {
    width: 100%;
    display: flex;
    position: relative;

    @media (max-width: 750px) {
      flex-direction: column;
    }

    > div {
      overflow: hidden;
      min-height: 50vh;
      width: 50%;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px;
      transition: 0.5s all;
      position: relative;
      flex-direction: column;
      padding: 20px;

      @media (max-width: 750px) {
        width: 100%;
        min-height: 70vh;
      }

      .filter {
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 5;
      }

      h2 {
        margin-top: 30px;
        margin-bottom: 10px;
        padding: 0px 20px;
        transition: 0.5s all;
        transition-delay: 0.1s;
        text-align: left;
        animation: ${fadeFromBottom} 1s;
      }

      > p {
        transition: 0.5s all;
        transition-delay: 0.2s;

        text-align: left;
        padding: 0px 10%;
        animation: ${fadeFromBottom} 1.2s;
      }
    }

    .Paragraph {
      @media (max-width: 750px) {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        height: 100%;
        width: 100%;
        z-index: 10;
      }
    }

    .primary {
      background: rgb(65, 193, 202);
      @media (max-width: 750px) {
        background: rgba(65, 193, 202, 0.9);
      }
    }

    .secondary {
      background: rgb(88, 41, 93);
      @media (max-width: 750px) {
        background: rgba(88, 41, 93, 0.9);
      }
    }
  }
`;

export const Title = styled.h2`
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 2px #222;
  margin-bottom: 70px;
  animation: ${fadeFromBottom} 0.7s;

  @media (max-width: 750px) {
    font-size: 42px;
  }
`;

export const AnimatedDiv = styled.div`
  width: 100%;
  height: 100%;
  animation: ${fadeFromBottom} 1s;
`;
