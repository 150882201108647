import styled, { keyframes } from 'styled-components';

export const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-50px);
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

export const fadeFromBottom = keyframes`
from {
  opacity: 0;
  transform: translateY(50px);
  filter: blur(4px);
}
to{
  opacity: 1;
  transform:translateY(0);
  filter: blur(0px);
}
`;

export const AnimatedDiv = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  background: none;
  padding: 0px;
  animation: ${fadeFromLeft} 1s;
`;
