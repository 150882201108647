import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiLogIn, FiMail, FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.jpg';
import { Helmet } from 'react-helmet';

import { FaChevronDown } from 'react-icons/fa';

import {
  Container,
  Content,
  Background,
  AnimatedDiv,
  CaseCard,
  CaseCardsContainer,
  CaseContainer,
  Manifesto,
  ContainerWithBackground,
  Title,
} from './styles';

import getValidationErrors from '../../utils/getValidationErrors';

import TextPopper from '../../components/TextPopper';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import Vimeo from '../../components/Vimeo';

import { Pattern } from '../../styles/ThemeComponents';
import VideoBackground from '../../components/VideoBackground';
import SliderContainer from '../../components/Slider';
import Scroller from '../../components/Scroller';
import { casesList } from '../CasesV1/cases';
import { Button } from '../../components/Button/styles';

const video = '/assets/caseSuergs2019.mp4';
const videoThumb = '/assets/caseSuergs2019-thumb.jpg';

interface CasesData {
  email: string;
  password: string;
}

interface ICases {
  ignore ?: string;
}

const CasesSlider: React.FC<ICases> = ({ignore}) => {

  const [list,setList] = useState<Array<JSX.Element>>([])

useEffect(() => {
const newList : Array<JSX.Element> = [];

  {casesList && casesList.map(caseInfo =>  {
     
    newList.push(     
    <CaseCard href={`/cases/${caseInfo.link_url}`} background={caseInfo.capa}>
    <header>
      <h3>{caseInfo.title_capa || caseInfo.title}</h3>
      <p>{caseInfo.client}</p>
    </header>

</CaseCard>) }
)}

setList([...newList]);

},[])
  
  return (
    <>
    
      <CaseContainer id="cases">
        <header>
          <Title>Gostou?</Title>
          <label className="inverted">Confira nossos Cases de Sucesso!</label>
        </header>
        <CaseCardsContainer>
         
          <SliderContainer slidesToShow={4} arrows autoplay={false} list={list}>
        

          </SliderContainer>
        
        </CaseCardsContainer>
        <a href="/cases" style={{marginTop: '20px'}}>
          <header>
          <button className="defaultButton">Veja Todos!</button>
          </header></a>
      </CaseContainer>
    </>
  );
};



export const CasesGrid: React.FC<ICases> = ({ignore}) => {
  return (
    <>
    
      <CaseContainer  >
        <header >
          <Title>Nossos Cases</Title>
          <label className="inverted">Confira parte da nossa história!</label>
        </header>
        <CaseCardsContainer>
         
   
          {casesList && casesList.map(caseInfo =>  
     
          
                  <CaseCard href={`/cases/${caseInfo.link_url}`} background={caseInfo.capa}>
                  <header>
                    <h3>{caseInfo.title_capa || caseInfo.title}</h3>
                    <p>{caseInfo.client}</p>
                  </header>
           
          </CaseCard> 
            )}
    

      
        </CaseCardsContainer>
      </CaseContainer>
    </>
  );
};
export default CasesSlider;
