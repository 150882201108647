import styled from 'styled-components';
import pattern from '../../assets/pattern.png';

export const Pattern = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: none;
  background-image: url(${pattern});
  background-repeat: repeat;
  opacity: 0.5;
  z-index: -1;
`;

export const Container = styled.div`
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #222;
  color: #fff;
  justify-content: space-between;

  @media (max-width: 750px) {
    flex-direction: column;
    padding: 20px;
  }
`;

interface ISlide {
  size?: string;
  background?: string;
  justify?: string;
  direction?: string;
  bgAttachment?: string;
  opacity?: string;
  mobileBackground?: string;
}

export const Slide = styled(Container)<ISlide>`
  min-height: ${props => (props.size ? props.size : '100vh')};
  min-width: 100%;
  background: url(${props => (props.background ? props.background : '')});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex !important;
  transition: 0.2s all;
  align-items: center;
  padding: 0px;

  justify-content: ${props => (props.justify ? props.justify : 'center')};
  flex-direction: ${props => (props.direction ? props.direction : 'column')};
  background-position: center;
  position: relative;
  background-attachment: ${props =>
    props.bgAttachment ? props.bgAttachment : 'initial'};

  opacity: ${props => (props.opacity ? props.opacity : '1')};
  img.icon {
    width: 30px;
    height: 30px;
    margin: 0px 10px;
  }

  img.icon.lg {
    width: 50px;
    height: 50px;
    margin: 0px 10px;
  }

  .flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 760px) {
    background: url(${props =>
      props.mobileBackground || props.background
        ? props.mobileBackground || props.background
        : ''});

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }
`;

interface ThemeDefinitionsProps {
  bodyBackground: string;
  bodyTextBackground: string;
  bodyBackgroundWithOpacity: string;
  input: Record<string, any>;
}

export const ThemeDefinitions: ThemeDefinitionsProps = {
  bodyBackground: 'rgb(38,89,165)',
  bodyBackgroundWithOpacity: 'rgba(35,33,40,0.9)',
  bodyTextBackground: '#FFF',
  input: {
    placeholder: '#ccc',
    background: '#555',
    color: 'white',
    border: '2px solid #555',
    colorFilled: '#ff9000',
    borderColorFilled: '#ff9000',
    colorFocused: '#ff9000',
    borderColorFocused: '#ff9000',
  },
};
