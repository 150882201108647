import React from 'react';

// import { Container } from './styles';
interface VimeoProps {
  id: string;
}

const Vimeo: React.FC<VimeoProps> = ({ id = '' }) => {
  return (
    <div
      className="video"
      style={{
        position: 'relative',
        paddingBottom: '56.25%' /* 16:9 */,
        paddingTop: 25,
        height: 0,
        width: '100%',
      }}
    >
      <iframe
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        title={id}
        src={`https://player.vimeo.com/video/${id}`}
      />
    </div>
  );
};

export default Vimeo;
